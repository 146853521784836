<template>
    <div class="star-ratings-css">
        <div class="stars-top" 
        :style="[{'background': 'url(' + require('@/assets/stars_top.svg') + ') top left no-repeat'},{'width': scale }]"></div>
        <div class="stars-bottom"
        :style="{'background': 'url(' + require('@/assets/stars_bottom.svg') + ') top left no-repeat'}"></div>
    </div>
</template>
<script>
export default {
    name: 'widgetStars',
    props: {
        scores: {
            type: Number,
            default: 0,
            required: true
        }
    },
    computed:{
        scale(){
            return ((this.scores/5) * 100) + '%';
        }
    }
}
</script>
<style scoped>
.star-ratings-css, .stars-top,.stars-bottom {
  height: 25px;
  width: 100px;
}
.stars-top,.stars-bottom {
  background-size: 100px auto !important;
}

.star-ratings-css {
  unicode-bidi: bidi-override;
  color: #c5c5c5;
  font-size: 25px;
  margin: 0;
  position: relative;
  padding: 0;
  text-shadow: 0px 1px 0 #a2a2a2;
}
.stars-top {
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
}
.stars-bottom {
    padding: 0;
    display: block;
    z-index: 0;
}

</style>