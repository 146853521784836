<template>
  <div>
    <!-- <div class="content-wrap"> -->
        <v-toolbar class="search-tools pa-0" flat>
          <!-- Action Button -->
          <v-col class="d-flex pl-0" cols="3" sm="3">
            <v-text-field
              label="Search Order No."
              v-model="options.filter.keyword"
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
            /> 
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="allZones"
              label="Zones"
              v-model="options.filter.zone"
              no-data-text="Select.."
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="approveSteps"
              label="Step"
              v-model="options.filter.approve_step"
              item-value="id"
              item-text="name"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="allTiers"
              label="Tiers"
              v-model="options.filter.tier"
              item-value="id"
              item-text="name"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="statusOptions"
              label="Status"
              v-model="options.filter.status"
              dense
              hide-details
            ></v-select>
          </v-col> -->
        </v-toolbar>
        <v-data-table 
            :items="filterList" 
            :headers="headers"
            :options.sync="options"
            sort-by="order_delivered_at"
            :sort-desc="true"
            no-data-text="There is no data"
            class="greyheader"
        >
          <template #item.total_amount="{value}">
              <span>{{value | comma}}</span>
          </template>
          <template #item.distance="{value}">
              <span>{{value | comma}}</span>
          </template>
          <template #item.wallet_food="{value}">
              <span>{{value | comma}}</span>
          </template>
          <template #item.wallet_fee="{value}">
              <span>{{value | comma}}</span>
          </template>
          <template #item.commission="{value}">
              <span>{{value | round(2)}}</span>
          </template>
          <template #item.order_created_at="{value}">
              <span>{{moment(value).format('DD-MM-YYYY HH:MM:SS')}}</span>
          </template>
          <template #item.order_delivered_at="{value}">
              <span>{{moment(value).format('DD-MM-YYYY HH:MM:SS')}}</span>
          </template>
          <!-- <template #item.action="{item}">
                <v-btn class="ma-1" outlined color="primary" icon tile small>
                  <v-icon small @click="()=>$router.push(`/drivers/${item.id}/details`)">mdi-text-box</v-icon>
                </v-btn>
          </template> -->
        </v-data-table>
    <!-- </div> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'DriveTripReport',
  props:{
    employee_id: {
      type: Number,
      default: -1,
      required: true
    },
  },
  data: () => ({
    search: '',
    options: {
        filter: {
          keyword: '',
          approve_step: null,
          zone: null,
          tier: null,
          status: null,
        }
    }
  }),
  methods: {
    ...mapActions(['getDriverTripsReport','getDriverTransactionsReport','getDriverRaitingsReport'])
  },
  computed: {
    ...mapGetters(['driverTripsReport']),
    headers(){
      return [
        {text: "Order No.", value: 'order_no'},
        {text: "Trip No.", value: 'trip_name'},
        {text: "Brand", value: 'brand_alias'},
        {text: "Payment Type", value: 'payment_type'},
        {text: "Order Status", value: 'order_status' , align: 'center'},
        {text: "Total Amount (Bht)", value: 'total_amount' , align: 'right'},
        {text: "Distance (M)", value: 'distance', align: 'right' },
        {text: "Delivery Fee (Bht)", value: 'delivery_fee' , align: 'right'},
        {text: "Driver Delivery Fee (Bht)", value: 'driver_delivery_fee', align: 'right' },
        {text: "Commission (Bht)", value: 'commission' , align: 'right'},
        {text: "Topup Food (Bht)", value: 'wallet_food', align: 'right' },
        {text: "Topup Fee (Bht)", value: 'wallet_fee', align: 'right' },
        // {text: "Topup Status", value: 'topup_status' },
        {text: "Order Created", value: 'order_created_at'},
        {text: "Order Delivered At", value: 'order_delivered_at'},
      ]
    },
    filterList() { 
      var filter = this.options.filter;
      return _.filter(this.driverTripsReport, function(query){
        var keyword = filter.keyword ? query.order_no.includes(filter.keyword) : true;
        // var keyword = filter.keyword ? query.employee_no.includes(filter.keyword) || query.name_th.includes(filter.keyword) || query.ssn.includes(filter.keyword) || query.phone.includes(filter.keyword) : true,
            // approve_step = filter.approve_step ? query.employee_approve_step.id == filter.approve_step : true,
            // zone = filter.zone ? query.site_id == filter.zone : true,
            // tier = filter.tier ? query.tier_id == filter.tier : true,
            // status = filter.status ? query.status_id == filter.status : true;
        return  keyword;
      })
    },
  },
  watch: {
      employee_id: function(){
        if(this.employee_id > 0){
          var initDate = [ moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
          this.getDriverTripsReport({ id: this.employee_id, filterDate: initDate });
          this.getDriverTransactionsReport({ id: this.employee_id, filterDate: initDate });
          this.getDriverRaitingsReport({ id: this.employee_id, filterDate: initDate });
        }
      }
  },
  created() {
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
</style>
