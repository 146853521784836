<template>
  <div id="profile">
    <v-avatar
    class="profile"
    color="grey"
    :width="imageWidth"
    :height="imageHeight"
    tile
    >
    <v-img :src="profileSRC"></v-img>
    </v-avatar>
    <uploadImage class="upload_profile" :img="profileImage" v-if="profileImage.image_path && editable"/>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import uploadImage from '@/components/drivers/upload_image.vue';

export default {
    name: 'ProfileImage',
    components: {
        uploadImage
    },
    props:{
      editable: {
      type: Boolean,
      default: true
      },
    },
    computed: {
      ...mapGetters(['profileImage']),
      profileSRC() {
        return this.profileImage.src
      },
      imageWidth() {
        if(this.editable)
          return 200
        else 
          return 110
      },
      imageHeight() {
        if(this.editable)
          return 250
        else 
          return 137
      }
    },
}
</script>
<style scoped>
#profile {
  position: relative;
  overflow: hidden;
  width: 180px;
}
.upload_profile{
  position: absolute;
  right:25px;
  bottom:12px;
}
</style>