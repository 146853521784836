<template>
    <div>
      <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <div v-show="canDo && canDo.can.includes('upload_images')">
              <v-btn v-if="img.slug != 'img-profile'" icon v-on="on">
                  <v-icon>mdi-pencil-box</v-icon>
              </v-btn>
              <v-btn v-if="img.slug == 'img-profile'" depressed dark fab x-small color="rgba(200, 200, 200, 0.5)" v-on="on">
                  <v-icon>mdi-pencil-box</v-icon>
              </v-btn>
            </div>
          </template>
          <!-- Modal Content -->
          <v-card>
          <v-card-title>
              <span class="headline">อัพโหลดรูป{{ img.title }}</span>
          </v-card-title>

          <v-card-text>
              <v-form v-model="form_valid" ref="form">
              <v-container>
                  <v-row>
                  <v-col cols="12" sm="12" md="12">
                      <v-file-input
                          v-model="upload_file"
                          :rules="rules"
                          accept="image/png, image/jpeg, image/bmp"
                          placeholder="Pick an image"
                          prepend-icon="mdi-camera"
                          label="Image"
                      ></v-file-input>
                  </v-col>
                  </v-row>
              </v-container>
              </v-form>
          </v-card-text>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
      </v-dialog>
      <Loader header="Uploading please wait..."/>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '@/components/common/loading.vue';

export default {
  name: 'upload_image',
  components: {
      Loader
  },
  props:{
      img: {
          type: Object,
          default: null
      }
  },
  data: () => ({
    dialog: false,
    upload_file: null,
    form_valid : false,
    rules: [
        value => !value || value.size < 5000000 || 'Image size should be less than 5 MB!',
    ]
  }),
  computed: {
      ...mapGetters(['canDo']),
  },
  methods: {
    ...mapActions(['uploadImage','setLoading']),

    close () {
      this.dialog = false
      setTimeout(() => {
        this.upload_file = null;
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },

    save () {
      if(this.form_valid){
        var uploadContent = {
            'slug' : this.img.slug,
            'folder' : this.img.image_path,
            'file' : this.upload_file
        }
        this.uploadImage(uploadContent);
        this.close();
      }
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
};
</script>
<style scoped>
.v-application .headline {
    font-size: 19px !important;
}
</style>