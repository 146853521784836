var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"search-tools pa-0",attrs:{"flat":""}},[_c('v-col',{staticClass:"d-flex pl-0",attrs:{"cols":"3","sm":"3"}},[_c('v-text-field',{staticClass:"hidden-sm-and-down",attrs:{"label":"Search Order No.","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.options.filter.keyword),callback:function ($$v) {_vm.$set(_vm.options.filter, "keyword", $$v)},expression:"options.filter.keyword"}})],1),_c('v-spacer')],1),_c('v-data-table',{staticClass:"greyheader",attrs:{"items":_vm.filterList,"headers":_vm.headers,"options":_vm.options,"sort-by":"transaction_sent_at","sort-desc":true,"no-data-text":"There is no data"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.trans_amount",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("comma")(value)))])]}},{key:"item.trans_verify_status",fn:function(ref){
var value = ref.value;
return [(value == 0)?_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v("Success")]):_c('v-chip',{attrs:{"color":"red darken-4","dark":"","small":""}},[_vm._v(_vm._s(value))])]}},{key:"item.trans_notify_status",fn:function(ref){
var value = ref.value;
return [(value == 0)?_c('v-chip',{attrs:{"color":"green","dark":"","small":""}},[_vm._v("Success")]):(value != null)?_c('v-chip',{attrs:{"color":"red darken-4","dark":"","small":""}},[_vm._v(_vm._s(value))]):_vm._e()]}},{key:"item.transaction_sent_at",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(_vm.moment(value).format('DD-MM-YYYY HH:mm:ss')))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }