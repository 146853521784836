<template>
  <div>
    <!-- <div class="content-wrap"> -->
        <v-toolbar class="search-tools pa-0" flat>
          <!-- Action Button -->
          <v-col class="d-flex pl-0" cols="3" sm="3">
            <v-text-field
              label="Search Order No."
              v-model="options.filter.keyword"
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
            /> 
          </v-col>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-data-table 
            :items="filterList" 
            :headers="headers"
            :options.sync="options"
            sort-by="rated_at"
            :sort-desc="true"
            no-data-text="There is no data"
            class="greyheader"
        >
          <template #item.rating_details="{value}">
              <ul>
                <li v-for="(i,index) in value" :key="index">{{ i | rating_d_options }}</li>
              </ul>
          </template>
          <template #item.rated_at="{value}">
              <span>{{moment(value).format('DD-MM-YYYY HH:MM:SS')}}</span>
          </template>
          <!-- <template #item.action="{item}">
                <v-btn class="ma-1" outlined color="primary" icon tile small>
                  <v-icon small @click="()=>$router.push(`/drivers/${item.id}/details`)">mdi-text-box</v-icon>
                </v-btn>
          </template> -->
        </v-data-table>
    <!-- </div> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  name: 'DriveRaitingsReport',
  props:{
    employee_id: {
      type: Number,
      default: -1,
      required: true
    },
  },
  data: () => ({
    search: '',
    callData: false,
    options: {
        filter: {
          keyword: '',
          approve_step: null,
          zone: null,
          tier: null,
          status: null,
        }
    },
  }),
  methods: {
    ...mapActions(['getDriverRaitingsReport']),
    genOptions(value){
      var d = this.rating_options.filter(item => item.id = value);
      return  d.name;
    }
  },
  filters:{
    rating_d_options(value){
      const rating_options = [
                                { id: 1, name: 'คุณภาพอาหารหลังการจัดส่ง' },
                                { id: 2, name: 'มารยาทและการบริการของคนขับ' },
                                { id: 3, name: 'ความถูกต้องของออเดอร์' },
                                { id: 4, name: 'ความรวดเร็วในการจัดส่ง' },
                                { id: 5, name: 'ความสะอาดและการแต่งการของคนชับ' },
                                { id: 6, name: 'รายการอาหารไม่ถูกต้อง' },
                                { id: 7, name: 'คนขับขอเรียกเก็บค่าบริการเพิ่มเติม' },
                                { id: 8, name: 'ความรวดเร็วในการจัดส่งอาหาร' },
                                { id: 9, name: 'ความสะอาดและการแต่งกายของคนขับ' },
                                { id: 10, name: 'ยังไม่ได้รับอาหารแต่ระบบแจ้งว่าออเดอร์จัดส่งแล้ว' },
                              ];
      var d = rating_options.filter(item => item.id == value);
      return  d[0].name;
    }
  },
  computed: {
    ...mapGetters(['driverRaitingsReport']),
    headers(){
      return [
        {text: "Order No.", value: 'order_no'},
        {text: "Payment Type", value: 'payment_type', align: 'center'},
        {text: "Delivery Fee (Bht)", value: 'delivery_fee', align: 'center'},
        {text: "Rating Stars", value: 'rating_score', align: 'center'},
        {text: "Raiting Details", value: 'rating_details' , align: 'left', width: '250px'},
        {text: "Comments", value: 'comments', width: '250px'},
        {text: "Rated At", value: 'rated_at' },
      ]
    },
    filterList() { 
      var filter = this.options.filter;
      return _.filter(this.driverRaitingsReport, function(query){
        var keyword = filter.keyword ? query.order_no.includes(filter.keyword) : true;
        return  keyword;
      })
    },
  },
  created() {
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
</style>
