var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"search-tools pa-0",attrs:{"flat":""}},[_c('v-col',{staticClass:"d-flex pl-0",attrs:{"cols":"3","sm":"3"}},[_c('v-text-field',{staticClass:"hidden-sm-and-down",attrs:{"label":"Search Order No.","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.options.filter.keyword),callback:function ($$v) {_vm.$set(_vm.options.filter, "keyword", $$v)},expression:"options.filter.keyword"}})],1),_c('v-spacer')],1),_c('v-data-table',{staticClass:"greyheader",attrs:{"items":_vm.filterList,"headers":_vm.headers,"options":_vm.options,"sort-by":"order_delivered_at","sort-desc":true,"no-data-text":"There is no data"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.total_amount",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("comma")(value)))])]}},{key:"item.distance",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("comma")(value)))])]}},{key:"item.wallet_food",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("comma")(value)))])]}},{key:"item.wallet_fee",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("comma")(value)))])]}},{key:"item.commission",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("round")(value,2)))])]}},{key:"item.order_created_at",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.moment(value).format('DD-MM-YYYY HH:MM:SS')))])]}},{key:"item.order_delivered_at",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.moment(value).format('DD-MM-YYYY HH:MM:SS')))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }