<template>
  <div>
    <!-- <div class="content-wrap"> -->
        <v-toolbar class="search-tools pa-0" flat>
          <!-- Action Button -->
          <v-col class="d-flex pl-0" cols="3" sm="3">
            <v-text-field
              label="Search Order No."
              v-model="options.filter.keyword"
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
            /> 
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="allZones"
              label="Zones"
              v-model="options.filter.zone"
              no-data-text="Select.."
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="approveSteps"
              label="Step"
              v-model="options.filter.approve_step"
              item-value="id"
              item-text="name"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="allTiers"
              label="Tiers"
              v-model="options.filter.tier"
              item-value="id"
              item-text="name"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="statusOptions"
              label="Status"
              v-model="options.filter.status"
              dense
              hide-details
            ></v-select>
          </v-col> -->
        </v-toolbar>
        <v-data-table 
            :items="filterList" 
            :headers="headers"
            :options.sync="options"
            sort-by="transaction_sent_at"
            :sort-desc="true"
            no-data-text="There is no data"
            class="greyheader"
        >
          <template #item.trans_amount="{value}">
              <span>{{value | comma }}</span>
          </template>
          <template #item.trans_verify_status="{value}">
              <v-chip v-if="value == 0" color="green" dark small>Success</v-chip>
              <v-chip v-else color="red darken-4" dark small>{{ value }}</v-chip>
          </template>
          <template #item.trans_notify_status="{value}">
              <v-chip v-if="value == 0" color="green" dark small>Success</v-chip>
              <v-chip v-else-if="value != null" color="red darken-4" dark small>{{ value }}</v-chip>
          </template>
          <template #item.transaction_sent_at="{value}">
              <span v-if="value">{{moment(value).format('DD-MM-YYYY HH:mm:ss')}}</span>
          </template>
          <!-- <template #item.action="{item}">
                <v-btn class="ma-1" outlined color="primary" icon tile small>
                  <v-icon small @click="()=>$router.push(`/drivers/${item.id}/details`)">mdi-text-box</v-icon>
                </v-btn>
          </template> -->
        </v-data-table>
    <!-- </div> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  name: 'DriveTransactionsReport',
  props:{
    employee_id: {
      type: Number,
      default: -1,
      required: true
    },
  },
  data: () => ({
    search: '',
    callData: false,
    options: {
        filter: {
          keyword: '',
          approve_step: null,
          zone: null,
          tier: null,
          status: null,
        }
    }
  }),
  methods: {
    ...mapActions(['getDriverTransactionsReport'])
  },
  computed: {
    ...mapGetters(['driverTransactionsReport']),
    headers(){
      return [
        {text: "Transaction ID", value: 'trans_id'},
        {text: "Transaction Type", value: 'trans_type', align: 'center'},
        {text: "Ref ID.", value: 'ref_id'},
        {text: "Payment Type", value: 'payment_type', align: 'center'},
        {text: "Amount", value: 'trans_amount' , align: 'right'},
        {text: "Wallet", value: 'transaction_wallet_code' , align: 'center'},
        {text: "Verify Status", value: 'trans_verify_status', align: 'center' },
        {text: "Notify Status", value: 'trans_notify_status', align: 'center' },
        {text: "Transaction Time", value: 'transaction_sent_at'},
      ]
    },
    filterList() { 
      var filter = this.options.filter;
      return _.filter(this.driverTransactionsReport, function(query){
        var keyword = filter.keyword ? (query.ref_id) && (query.ref_id.includes(filter.keyword)) : true;
        return  keyword;
      })
    },
  },
  created() {
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
</style>
